import React, { useEffect, useState } from 'react'
import portfolio1 from "../assets/portfolio-1.jpg"
import portfolio2 from "../assets/portfolio-2.jpg"
import portfolio3 from "../assets/portfolio-3.jpg"
import portfolio4 from "../assets/portfolio-4.jpg"
import portfolio5 from "../assets/portfolio-5.jpg"
import portfolio6 from "../assets/portfolio-6.jpg"

function Footer() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (

        <div class="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
            <div class="container py-5 px-lg-5">
                <div class="row g-5">
                    <div class="col-md-6 col-lg-4">
                        <p class="section-title text-white h5 mb-4">Address<span></span></p>
                        <p className='d-flex mb-auto'><i class="fa fa-map-marker-alt me-2"></i>
                        <p> Office 1/7, 3rd Floor, Silk Center, Rehmanabad, Rawalpindi
                        </p>
                        </p>
                        <p className='d-flex mb-auto'><i class="fa fa-phone-alt me-2"></i>
                        <p>+92311-5368084 <p> +92315-5724477</p></p>
                        </p>
                        <p><i class="fa fa-envelope me-2"></i>info@brainscraft.io</p>
                        <div class="d-flex pt-2">
                            <a class="btn btn-outline-light btn-social" target='_blank' href="#"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-outline-light btn-social" target='_blank'  href="https://www.facebook.com/profile.php?id=61565314360835&mibextid=JRoKGi"><i class="fab fa-facebook-f"></i></a>
                          
                            <a class="btn btn-outline-light btn-social" target='_blank' href="https://www.linkedin.com/company/brainscraft"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <p class="section-title text-white h5 mb-4">Quick Link<span></span></p>
                        <a class="btn btn-link" href="/about">About Us</a>
                        <a class="btn btn-link" href="/contact">Contact Us</a>
                        
                        
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <p class="section-title text-white h5 mb-4">Gallery<span></span></p>
                        <div class="row g-2">
                            <div class="col-4">
                                <img class="img-fluid" src={portfolio1} alt="Image"></img>
                            </div>
                            <div class="col-4">
                                <img class="img-fluid" src={portfolio2} alt="Image"></img>
                            </div>
                            <div class="col-4">
                                <img class="img-fluid" src={portfolio3} alt="Image"></img>
                            </div>
                            <div class="col-4">
                                <img class="img-fluid" src={portfolio4} alt="Image"></img>
                            </div>
                            <div class="col-4">
                                <img class="img-fluid" src={portfolio5} alt="Image"></img>
                            </div>
                            <div class="col-4">
                                <img class="img-fluid" src={portfolio6} alt="Image"></img>
                            </div>
                        </div>
                        
                    </div>
                 
                </div>
            </div>
            <div class="container px-lg-5">
                <div class="copyright">
                    <div class="row">
                        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a class="border-bottom" href="/">Brainscraft</a>, All Right Reserved.


                           
                        </div>
                        <div class="col-md-6 text-center text-md-end">
                            <div class="footer-menu">
                                <a href="/">Home</a>
                                <a href="#">Cookies</a>
                                <a href="#">Help</a>
                                <a href="#">FQAs</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            {isVisible && (
                <div
                    className="btn btn-warning back-to-top  "
                    onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50px',
                        height: '50px',
                        
                        borderRadius: '50%',
                        fontSize: '24px',
                        transition: 'opacity 0.3s',
                    }}
                >
                    &#8679;
                </div>
            )}
        </div>
        </div>

    )
}

export default Footer